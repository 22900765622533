.container {
  display: flex;
  flex-direction: column;

  width: 30rem;

  padding: 1rem;

  row-gap: .5rem;

  overflow-y: scroll;

  color: #282828;
  background-color: #EEEEEE;

  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 45rem) {
  .container {
    display: none;
  }
}

.card {
  padding: .75rem;

  border: 1px solid #A4C3CB;
  border-radius: .5rem;

  box-shadow: .25rem .25rem .5rem .25rem rgba(0, 0, 0, 0.05);

  cursor: pointer;

  transition: .2s background-color;
}

.card:hover {
  background: #E5EEF0;
}

.name {
  font-size: .9em;
}

.placeholder {
  margin: auto;

  font-size: 1.1rem;
}
